<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12 mt-2">
      <div class="card-header flex justify-content-between">
        <div class="card-title">{{ $route.name }} List</div>
        <el-button type="text" @click="showSearch = !showSearch"><font-awesome-icon icon="search" /></el-button>
      </div>
      <div class="card-body" v-if="showSearch">
        <div class="flex">
          <div class="w-1/2">
            <el-form ref="form" label-width="120px">
              <el-form-item label="Transaction Date">
                <el-date-picker v-model="search.date" type="daterange" size="small" :picker-options="datePickerOptions"
                  class="w-full" range-separator="To" start-placeholder="Start date" end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Search Keyword">
                <!-- <el-input v-if="search.search_by === 'invoice_number'" clearable size="small" type="text" v-model="search.search_keyword" -->
              <el-input clearable size="small" type="text" v-model="search.search_keyword"
                  placeholder="Input search keyword" class="mr-2" />
              </el-form-item>
            </el-form>
          </div>
          <div class="w-1/2">
            <el-form ref="form2" label-width="150px">
              <el-form-item label="Transaction Status">
                <el-select size="small" v-model="search.status_filter" class="mr-2">
                  <el-option v-for="(item, index) in status_options" :key="index" :value="item.value"
                    :label="item.label" />
                </el-select>
              </el-form-item>
              <el-form-item label="Filter Type">
                <el-select size="small" v-model="search.search_by" class="mr-2">
                  <el-option v-for="(item, index) in search_options" :key="index" :value="item.value"
                    :label="item.text" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="flex">
          <div class="w-1/2">
            &nbsp;
          </div>
          <div class="w-1/2">
            <el-form ref="form2" label-width="150px">
              <el-form-item label="Payment Vendor">
                <el-select size="small" v-model="search.payment_vendor_filter" class="mr-2">
                  <el-option v-for="(item, index) in payment_vendor_filter_options" :key="index" :value="item.value"
                    :label="item.label" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search" />
          Search</el-button>
        <el-button type="primary" @click="handlerDownloadBtn" size="small"><font-awesome-icon icon="download" />
          Download</el-button>
      </div>
      <div class="table-container">
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="25%">Transaction Date</th>
                <th width="25%">Invoice Number</th>
                <th width="20%">User Name</th>
                <th width="20%">User Email</th>
                <th width="10%">Amount</th>
                <th width="10%">Discount</th>
                <th width="10%">Admin Fee</th>
                <th>Payment Vendor</th>
                <th>Payment Method</th>
                <th width="20%" class="child2-sticky">Status</th>
                <th class="child1-sticky">Action</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td class="table-sticky-left">{{ item.created_at_str }}</td>
                <td class="table-sticky-left">{{ item.invoice_number }}</td>
                <td>{{ item.user_full_name }}</td>
                <td>{{ item.user_email }}</td>
                <td>{{ item.amount_str }}</td>
                <td>{{ item.discount_str }}</td>
                <td>{{ item.admin_fee_str }}</td>
                <td>{{ item.payment_vendor_str }}</td>
                <td>{{ item.pm_payment_name }}</td>
                <td class="text-nowrap text-muted child2-sticky"><span class="tag" :class="item.status_color_str">{{
                  item.status_str }}</span></td>
                <td class="text-nowrap text-muted child1-sticky">
                  <button @click="viewDetailTransaction(item)" title="View Detail" type="button"
                    class="btn btn-icon btn-flickr btn-sm"><font-awesome-icon icon="eye" /></button>
                </td>
                <td class="text-nowrap text-muted child1-sticky">
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
      </div>
      <pagination :page="page" :per_page="per_page" :list="list" :total_rows="total_rows"
        @pageChange="pageChangeHandler" />
    </div>
    <!-- MODAL TRANSACTION DETAIL-->
    <b-modal size="xl" v-model="showDetailTransaction" :title="'Transaction #' + selected_transaction.invoice_number"
      hide-footer>
      <el-skeleton :loading="loading_detail" :rows="6" animated />
      <el-descriptions v-if="!loading_detail" class="margin-top mb-3" title="Order Information" :column="2" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            Transaction Date
          </template>
          {{ selected_transaction.created_at_str }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-document"></i>
            Invoice Number
          </template>
          {{ selected_transaction.invoice_number }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            User Name
          </template>
          {{ selected_transaction.user_full_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-phone"></i>
            Phone Number
          </template>
          {{ selected_transaction.user_phone_number }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-message"></i>
            Email
          </template>
          {{ selected_transaction.user_email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-wallet"></i>
            Payment Method
          </template>
          {{ selected_transaction.pm_payment_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-finished"></i>
            Payment Status
          </template>
          <span class="tag" :class="selected_transaction.status_color_str">{{ selected_transaction.status_str }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-c-scale-to-original"></i>
            Virtual Account Number
          </template>
          {{ selected_transaction.virtual_account_number || '-' }}
        </el-descriptions-item>
        <!-- del -->
        <el-descriptions-item v-if="selected_transaction.payment_vendor_str === 'Dipay'">
          <template slot="label">
            <i class="el-icon-finished"></i>
            Payment Status Vendor
          </template>
          <!-- <button v-b-toggle.check-dipay-status @click="checkDipayStatus(selected_transaction)" title="View Detail Refund" type="button" class="btn btn-icon btn-flickr btn-sm"> Check Dipay Status </button> -->
          <el-button @click="toggleCollapse" type="primary" size="small"> <i class="fa fa-spinner fa-spin"></i> Check Dipay Status</el-button>
          <div v-if="!collapseOpened" class="tooltip show tooltip-inner tooltip-container" align="center" role="tooltip">
            <div class="tooltip-arrow"></div>
            <div class="tooltip-inner">
                Click this
              <i class="el-icon-top"></i>
              <i class="el-icon-top"></i>
            </div>
          </div>
        </el-descriptions-item>
         <el-descriptions-item v-if="selected_transaction.payment_vendor_str === 'Dipay'">
          <template slot="label">
            <i class="el-icon-finished"></i>
            Refund
          </template>
          <el-button :disabled="isRefundDisabled(selected_transaction) || !isBothCollapseOpened" @click="confirm()" type="primary" size="small"> <i class="fa fa-spinner fa-spin"></i> Refund</el-button>
        </el-descriptions-item>
      </el-descriptions>
       <!-- OTP Modal refund -->
      <el-dialog :visible.sync="dialogVisibleOtp" title="Confirmation" width="30%">
        <p>Are you sure you want to refund this product?</p>
        <el-form>
        <el-form-item label="Select Venteny Name" prop="selectedOption">
          <el-select v-model="selectedOption" placeholder="Select" size="small" class="mr-2">
            <el-option
              v-for="option in selectOptions"
              :key="option.company_id"
              :label="option.name"
              :value="option.company_id"
            ></el-option>
          </el-select>
        </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleOtp = false">Cancel</el-button>
          <el-button type="primary" @click="confirm">Confirm</el-button>
        </span>
      </el-dialog>
      <el-dialog :visible.sync="otpModalVisible" title="Enter OTP" width="30%">
        <el-form ref="otpForm" :model="otpForm" label-width="100px">
          <el-form-item label="OTP" prop="otp" required>
            <el-input 
              v-model="otpForm.otp" 
              placeholder="Enter OTP" 
              :maxlength="6"            
              :show-password="true"     
              @input="formatOTP"       
            ></el-input>
          </el-form-item>
          <el-form-item label="Reason" prop="reason" required>
            <el-input v-model="otpForm.reason" placeholder="Enter Reason"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="closeOTPModal">Cancel</el-button>
          <!-- <el-button :disabled="otpForm.otp === '' || otpForm.reason === ''" type="primary" @click="verifyOTP(selected_transaction)">Verify</el-button> -->
          <!-- || verifyOTPRepurchase(selected_transaction) -->
          <el-button :disabled="otpForm.otp === '' || otpForm.reason === ''" type="primary" @click="verifyOTP(selected_transaction)">Verify</el-button>
        </span>
      </el-dialog>
       <!-- OTP Modal repurchase -->
      <el-dialog :visible.sync="dialogVisibleOtpRepurchase" title="Confirmation" width="30%">
        <p>Are you sure you want to repurchase this product?</p>
        <el-form>
        <el-form-item label="Select Venteny Name" prop="selectedOption">
          <el-select v-model="selectedOption" placeholder="Select" size="small" class="mr-2">
            <el-option
              v-for="option in selectOptions"
              :key="option.company_id"
              :label="option.name"
              :value="option.company_id"
            ></el-option>
          </el-select>
        </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleOtpRepurchase = false">Cancel</el-button>
          <el-button type="primary" @click="confirmRepurchase">Confirm</el-button>
        </span>
      </el-dialog>
      <el-dialog :visible.sync="otpModalVisibleRepurchase" title="Enter OTP" width="30%">
        <el-form ref="otpForm" :model="otpForm" label-width="100px">
          <el-form-item label="OTP" prop="otp" required>
            <el-input 
              v-model="otpForm.otp" 
              placeholder="Enter OTP" 
              :maxlength="6"            
              :show-password="true"     
              @input="formatOTP"       
            ></el-input>
          </el-form-item>
          <el-form-item label="Reason" prop="reason" required>
            <el-input v-model="otpForm.reason" placeholder="Enter Reason"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="closeOTPModal">Cancel</el-button>
        <el-button :disabled="otpForm.otp === '' || otpForm.reason === ''" type="primary" @click="verifyOTPRepurchase(selected_transaction)">Verify</el-button>
        </span>
      </el-dialog>
      <div v-if="dialogVisibleOtpRepurchase" class="overlay"></div>
      <div v-if="dialogVisibleOtp" class="overlay"></div>
      <div v-if="otpModalVisibleRepurchase" class="overlay"></div>
      <div v-if="otpModalVisible" class="overlay"></div>
      <b-collapse id="check-dipay-status" v-model="collapseOpened" :duration="300">
        <b-card>
          <div v-if="loadingDipayStatus" class="d-flex justify-content-center align-items-center" style="height: 200px;">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else class="table-responsive">
            <p> Dipay Status </p>
            <table class="table table-vcenter w-full">
              <thead>
                <tr>
                  <th>Amount Value</th>
                  <th>Response Code</th>
                  <th>Original Reference No</th>
                  <th>Original Partner Reference_no</th>
                  <th>Transaction Status Desc</th>
                </tr>
              </thead>
              <tbody v-if="data_dipay">
                <tr>
                  <td>{{ formatCurrency(data_dipay.amount?.value) }}</td>
                  <td>{{ data_dipay.response_code }}</td>
                  <td>{{ data_dipay.original_reference_no }}</td>
                  <td>{{ data_dipay.original_partner_reference_no }}</td>
                  <td>
                    <span size="xs" v-if="data_dipay.transaction_status_desc === 'Success'" class="tag tag-success">{{ data_dipay.transaction_status_desc }}</span>
                    <span v-else-if="data_dipay.transaction_status_desc === 'Failed'" class="tag tag-danger">{{ data_dipay.transaction_status_desc }}</span>
                    <span v-else-if="data_dipay.transaction_status_desc === 'Process'" class="tag tag-info">{{ data_dipay.transaction_status_desc }}</span>
                    <span v-else-if="data_dipay.transaction_status_desc === 'Waiting Payment'" class="tag tag-warning">{{ data_dipay.transaction_status_desc }}</span>
                    <span v-else-if="data_dipay.transaction_status_desc === 'Refund Success' || 'Refunded'" class="tag tag-cyan">{{ data_dipay.transaction_status_desc }}</span>
                    <span v-else>{{ data_dipay.transaction_status_desc }}</span> 
                  </td>
                </tr>
              </tbody>
            </table>
          </div> 
          <!-- <div v-else>
            No data available
          </div> -->
        </b-card>
          <!-- endpoint 2 -->
        <!-- <b-card>
          <div v-if="loadingDipayLog" class="d-flex justify-content-center align-items-center" style="height: 200px;">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else>
          <p> Log Dipay</p>
            <div class="table-responsive">
              <table class="table table-vcenter w-full">
                <thead>
                  <tr>
                    <th> Vendor ID </th>
                    <th> Url Hit </th>
                    <th> Response </th>
                    <th> Invoice  </th>
                    <th> Created On </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in log_dipay" :key="item.id">
                    <td>{{ item?.vendor_id }}</td>
                    <td>{{ item?.vendor_id }}</td>
                    <td>{{ item?.vendor_id }}</td>
                    <td>{{ item?.vendor_id }}</td>
                    <td>{{ item?.vendor_id }}</td>
                  </tr>
                </tbody>
              </table>
              <el-empty description="No data found" v-if="log_dipay == 0" :image="emptyStateImage"></el-empty>
            </div>
          </div>
        </b-card> -->
        <!-- <b-table striped hover :items="formattedDataLog" :fields="fieldsPurchase" v-if="formattedDataLog.length > 0"></b-table>
        <div v-else>
          No data available
        </div> -->
      </b-collapse>
      <br>
      <div class="table-container">
        <div class="table-responsive">
          <table v-if="!loading_detail" class="table table-vcenter w-full">
            <thead>
              <tr>
                <th width="15%">Product Name</th>
                <th>Type</th>
                <th>Destination Number</th>
                <th>Vendor</th>
                <th>Qty</th>
                <th>Base Price</th>
                <th>Sell Price</th>
                <th>Ref. Number</th>
                <th>Total</th>
                <th>Status</th>
                <th>Action Product</th>
                <th>Action Repurchase</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in selected_transaction.details" :key="i">
                <td>{{ item.product.name }}</td>
                <td>{{ item.source_type_str }}</td>
                <td>{{ item.hp || "-" }}</td>
                <td>{{ item.vendor ? item.vendor.vendor_name : "-" }}</td>
                <td>{{ item.product.quantity }}</td>
                <td>{{ formatCurrency(item.product.base_price) }}</td>
                <td>{{ item.product.sell_price }}</td>
                <td>{{ item.ref_vendor }}</td>
                <td> {{ item.product.total_price }} </td>
                <td>
                  <el-popover v-if="item.order_detail_status == 0 && item.order_detail_message" placement="left" width="200"
                    trigger="hover" :content="item.order_detail_message">
                    <span slot="reference" class="tag" :class="item.status_color">{{ item.status_str }}</span>
                  </el-popover>
                  <span v-else class="tag" :class="item.status_color">{{ item.status_str }}</span>
                </td>
                <td> 
                  <div>
                    <!-- <el-button :disabled="item.product_vendor !== 1" @click="checkProductStatus()"  v-b-toggle.product-status class="btn-outline-primary" size="small"> <i  v-if="loading" class="fa fa-spinner fa-spin"></i> Check Status</el-button> -->
                    <el-button :disabled="item.product_vendor !== 1" @click="toggleCollapseProduct(item.id)" class="btn-outline-primary" size="small"> <i  v-if="loading" class="fa fa-spinner fa-spin"></i> Check Status</el-button>
                      <div v-if="!collapseOpenedProduct" class="tooltip show tooltip-inner tooltip-container" align="center" role="tooltip">
                        <div class="tooltip-arrow"></div>
                        <div class="tooltip-inner">
                            Click this
                          <i class="el-icon-top"></i>
                          <i class="el-icon-top"></i>
                        </div>
                      </div>
                  </div>
                </td>
                <td>
                  <el-button :disabled="!(item.source_type_str === 'Voucher' && item.vendor.vendor_name === 'Mobile Pulsa' && item.product_vendor === 1 && isSameMonth(selected_transaction.created_at_str)) || item.status_str === 'Success' && selected_transaction.status_str === 'Success' || !isBothCollapseOpened" @click="confirmRepurchase()" size="small" type="primary"> Repurchase </el-button>
                </td>
               </tr>
               <tr>
                <td colspan="13">
                  <b-collapse id="product-status" v-model="collapseOpenedProduct">
                    <b-card>
                      <br>
                      <div v-if="loadingProductStatus" class="d-flex justify-content-center align-items-center" style="height: 200px;">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <div v-else>
                        <p> Product Status </p>
                        <div class="table-responsive">
                          <table class="table table-vcenter w-full">
                            <thead>
                              <tr>
                                <th>Ref ID</th>
                                <th>Price</th>
                                <th>Balance</th>
                                <th>Transaction ID</th>
                                <th>RC</th>
                                <th>Code</th>
                                <th>HP</th>
                                <th>Message</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="item in product_status" :key="item.id">
                                <td>{{ item.ref_id }}</td>
                                <td>{{ formatCurrency(item.price) }}</td>
                                <td>{{ item.balance }}</td>
                                <td>{{ item.tr_id }}</td>
                                <td>{{ item.rc }}</td>
                                <td>{{ item.code }}</td>
                                <td>{{ item.hp }}</td>
                                <td>
                                  <span size="xs" v-if="item.message === 'SUCCESS'" class="tag tag-success">{{ item.message }}</span>
                                  <span v-else-if="item.message === 'FAILED'" class="tag tag-danger">{{ item.message }}</span>
                                  <span v-else-if="item.message === 'PROCESS'" class="tag tag-info">{{ item.message }}</span>
                                  <span v-else-if="item.message === 'WAITING PAYMENT'" class="tag tag-warning">{{ item.message }}</span>
                                  <span v-else>{{ item.message }}</span> 
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <!-- <el-empty description="No data found" v-if="product_status.length === 0" :image="emptyStateImage"></el-empty> -->
                        </div>
                      </div>
                    </b-card>
                  </b-collapse>
                </td>
               </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="8"><i>Admin Fee</i></td>
                <td>{{ selected_transaction.admin_fee_str }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr v-for="(item, i) in selected_transaction.additional_data" :key="i">
                <td colspan="8"><i>{{ item.additional_name }}</i></td>
                <td><i>{{ item.additional_fee_str }}</i></td>
              </tr>
              <tr v-for="(item, i) in selected_transaction.detail_discount" :key="i">
                <td colspan="8"><i>{{ item.promo_name }}</i></td>
                <td><i>-{{ item.promo_discount_str }}</i></td>
              </tr>
              <tr style="background-color: #f4f6fa; font-weight: bold;">
                <td colspan="8">Subtotal</td>
                <td>{{ selected_transaction.amount_str }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { upperFirst } from 'lodash';
import { GET_TRANSACTIONS, DETAIL_TRANSACTION, DOWNLOAD_TRANSACTIONS, GET_REFUND_REPORT, DOWNLOAD_REFUND } from '@/store/modules/transactions';
import { STATUS_DIPAY, LOG_DIPAY, STATUS_PRODUCT, GET_REFUND_OTP, GET_REPURCHASE_OTP, REFUND_VERIFY, REPURCHASE_VERIFY } from '@/store/modules/app_user';
import emptyStateImage from '@/assets/images/empty-state.png';
import Pagination from '../components/Pagination.vue';

export default {
  components: { Pagination },
  name: 'Refund and Repurchase',
  metaInfo: {
    title: 'Refund and Repurchase for Dipay',
  },
  data() {
    return {
      fields: [
        { key: 'original_reference_no', label: 'Original Reference No' },
        { key: 'original_partner_reference_no', label: 'Original Partner Reference No' },
        { key: 'amount', label: 'Amount' },
        { key: 'transaction_status_desc', label: 'Status' },
      ],
      fieldsPurchase: [
        { key: 'vendor_id', label: 'Vendor ID' },
      ],
      collapseOpened: false,
      collapseOpenedProduct: false,
      selectOptions: [
        { name: 'Venteny Growth (ges)', company_id: 6667 },
        { name: 'PT Venteny Matahari Indonesia', company_id: 6 }
      ],
      company_id: 6667,
      selectedOption: '',
      otpModalVisible: false,
      otpModalVisibleRepurchase: false,
      otpForm: {
        otp: '',
        reason: '',
        selectedOption: ''
      },
      user_id: null,
      showDetailTransaction: false,
      showSearch: true,
      search_options: [
        { value: 'invoice_number', text: 'Invoice Number' },
        { value: 'full_name', text: 'Full Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
      ],
      emptyStateImage,
      merchantList: [],
      merchant_id: '',
      filter_merchant_loading: false,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loading_detail: false,
      loadingProductStatus: false,
      loadingDipayStatus: false,
      loadingDipayLog: false,
      loader: null,
      search_keyword: '',
      search_by: '',
      search_merchant_keyword: '',
      selected_transaction: {
        user: {},
      },
      loading: {
        summary_vendor: false,
      },
      search: {
        date: [
          moment().subtract(30, 'day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        search_by: 'invoice_number',
        search_keyword: '',
        start_date: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        status_filter: '',
        payment_vendor_filter: '',
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
      summary_vendor: [],
      status_options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Waiting Payment',
          value: 1,
        },
        {
          label: 'Process',
          value: 2,
        },
        {
          label: 'Success',
          value: 3,
        },
        {
          label: 'Expired',
          value: 4,
        },
        {
          label: 'Failed',
          value: 5,
        },
      ],
      payment_vendor_filter_options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Qris',
          value: 'qris',
        },
        {
          label: 'Espay',
          value: 'espay',
        },
        {
          label: 'M-bayar',
          value: 'e2pay',
        },
        {
          label: 'Xendit',
          value: 'xendit',
        },
        {
          label: 'Dipay',
          value: 'dipay',
        },
      ],
      selected_transaction: {  },
      data_verify_repurchase: [],
      data_dipay: [],
      data_Verify:[],
      log_dipay: [],
      product_status: [],
      data_OTP: [],
      data_OTP_repurchase: [],
      loadingButton: false,
      // PIN
      dialogVisibleOtp: false,
      dialogVisibleOtpRepurchase: false,
    };
  },
  async created() {
    this.user_id = localStorage.getItem('user_id');
    // console.log('User ID bener:', this.user_id);
  },
  async mounted() {
    const loader = this.$loading.show();
    this.page = 1;
    this.search.action = '';
    this.search.start_date = moment(this.search.date[0]).format('YYYY-MM-DD');
    this.search.end_date = moment(this.search.date[1]).format('YYYY-MM-DD');
    // dircly set payment_vendor_filter to 'dipay' and 'success'
    this.search.payment_vendor_filter = 'dipay';
    this.search.status_filter = 3;
    await this.getList();
    loader.hide();
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    // new
    formattedData() {
      return this.data_dipay.map(item => ({
        original_reference_no: item.data.original_reference_no,
        original_partner_reference_no: item.data.original_partner_reference_no,
        amount: this.formatCurrency(item.data.amount?.value),
        transaction_status_desc: item.data.transaction_status_desc,
      }));
    },
    formattedDataLog() {
      return this.log_dipay.map(item => ({
        vendor_id: item.data.vendor_id,
      }));
    },
    isBothCollapseOpened() {
      return this.collapseOpened && this.collapseOpenedProduct;
    }
  },
  methods: {
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.search.action = '';
      this.search.start_date = moment(this.search.date[0]).format('YYYY-MM-DD');
      this.search.end_date = moment(this.search.date[1]).format('YYYY-MM-DD');
      this.getList();
    },
    searchMerchant(query) {
      this.search_merchant_keyword = query;
      this.getMerchantList();
    },
    handlerDownloadBtn() {
      this.search.action = 'download';
      this.getList();
    },
    async getList() {
      this.isLoading = true;
      await this.$store.dispatch(this.search.action === 'download' ? DOWNLOAD_REFUND : GET_REFUND_REPORT, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
        ...this.search,
      }).then((result) => {
        if (this.search.action === 'download') {
            const rows = result.data.data.rows;
            if (rows && Array.isArray(rows) && rows.length > 0) {
              const csvContent = rows.map(row => Object.values(row).join(',')).join('\n');
              const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
              const anchor = document.createElement('a');
              anchor.href = csvData;
              anchor.target = '_blank';
              anchor.download =  `Refund-Repurchase-Report-${this.search.start_date}-${this.search.end_date}.csv`;
              anchor.click();
            } else {
                console.error('No data found in rows array.');
            }
        } else {
            const { rows, count } = this.$store.getters.refund_report;
            this.total_rows = count || 0;
            this.list = rows;
        } 
      })
      this.isLoading = false;
    },
    async viewDetailTransaction(item) {
      try {
        this.collapseOpened = false,
        this.collapseOpenedProduct = false,
        this.selected_transaction = item;
        this.showDetailTransaction = true;
        this.loading_detail = true;
        await this.$store.dispatch(DETAIL_TRANSACTION, item.invoice_number);
        this.transactionDetails = this.$store.getters.trx.details.map((v) => {
          return {
            order_id: v.order_id,
            source_type: v.source_type,
            product_vendor: v.product_vendor,
            // new
            id: v.id
          };
        });
        this.selected_transaction.details = this.$store.getters.trx.details.map((v) => {
          const {
            detail_product, quantity, sell_price, total_price, v_price, base_price, transaction_qri, amount, insurance_order, voucher
          } = v;
          v.source_type_str = upperFirst(v.source_type);
          if (v.source_type === 'v_price') {
            v.source_type_str = 'PPOB';
          } else if (v.source_type === 'special_program') {
            v.source_type_str = 'Spesial Program';
          } else if (v.source_type === 'lms') {
            v.source_type_str = 'Academy';
          }
          v.product = {};
          if (v.source_type === 'voucher') {
            v.product = {
              name: detail_product ? detail_product?.voucher_name : voucher.voucher_name,
              base_price: detail_product ? detail_product?.voucher_base_price : voucher.voucher_base_price ? `Rp. ${detail_product ? detail_product?.voucher_base_price : voucher.voucher_base_price.toLocaleString('id-ID')}` : detail_product ? detail_product?.voucher_base_price : voucher.voucher_base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
            };
          } else if (v.source_type === 'v_price') {
            v.product = {
              name: v_price.v_price_type ? [v_price.v_price_type, v_price.nominal].join(' ') : v_price.product_code,
              base_price: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
            };
            if (v_price.vendor_id.toString() === '8') {
              if (v.detail_product.provider && v.detail_product.nominal) {
                v.product.name = [v.detail_product.provider, v.detail_product.nominal].join(' ');
              } else {
                v.product.name = v_price.nominal;
              }
            }
          } else if (v.source_type === 'academic') {
            v.product = {
              name: detail_product.academic_name,
              base_price: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
            };
            // name: transaction_qri.transaction_qris_name || '',
          } else if (v.source_type === 'qris') {
            v.product = {
              name: transaction_qri && transaction_qri.transaction_qris_name ? transaction_qri.transaction_qris_name : '',
              base_price: '-',
              sell_price: '-',
              quantity: 1,
              total_price: amount ? `Rp. ${amount.toLocaleString('id-ID')}` : amount,
            };
          } else if (v.source_type === 'insurance') {
            v.product = {
              name: insurance_order.insurance_name,
              base_price: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
            };
          } else if (v.source_type === 'special_program') {
            v.product = {
              name: detail_product.productName,
              base_price: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : base_price,
              sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
            };
          } else if (v.source_type === 'lms') {
            v.product = {
              name: detail_product.name,
              base_price,
              base_price_str: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : '-',
              sell_price,
              sell_price_str: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : '-',
              quantity,
              total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : '-',
            };
          }
          v.status_str = 'Failed';
          v.status_color = 'tag-danger';

          if (v.order_detail_status === 1) {
            v.status_str = 'Process';
            v.status_color = 'tag-info';
            if (this.selected_transaction.status === 1) {
              v.status_str = 'Waiting payment';
              v.status_color = 'tag-warning';
            }
          } else if (v.order_detail_status === 2) {
            v.status_str = 'Success';
            v.status_color = 'tag-success';
          } else if (v.order_detail_status === 3) {
            v.status_str = 'Refund';
            v.status_color = 'tag-teal';
          }
          return v;
        });
      } catch (error) {
      } finally {
        this.loading_detail = false;
      }
    },
    showUserData(data, value) {
      // return data?.user?.[value];
      return data?.user?.[value];
    },
    async checkDipayStatusAndLog(selected_transaction) {
      // first method
      await this.checkDipayStatus(selected_transaction);
      //  second method
      await this.checkDipayLog(selected_transaction);
    },
    // async checkDipayStatus(selected_transaction) {
    //   this.loadingDipayStatus = true
    //   try {
    //     if (!selected_transaction.owned_by) {
    //       return;
    //     }
    //     this.data_dipay = [];
    //     const requestBody = {
    //       invoice_number: selected_transaction.invoice_number, 
    //       owned_by: selected_transaction.owned_by
    //     };
    //       const response = await this.$store.dispatch(STATUS_DIPAY, requestBody);
    //       // console.log("Response from STATUS_DIPAY:", response);
    //       // const response = await this.$store.dispatch(STATUS_DIPAY, { data: requestBody });
    //       // modify status_str and status_color based on transaction_status_desc
    //       this.data_dipay = response.map(item => {
    //         if (item.data.transaction_status_desc === 'Success') {
    //           item.data.dipay_status_color = 'tag-success';
    //         } else if (item.data.transaction_status_desc === 'Refund Success' || item.data.transaction_status_desc === 'Refunded') {
    //           item.data.dipay_status_color = 'tag-teal';
    //         } else if (item.data.transaction_status_desc === 'Waiting payment' || item.data.transaction_status_desc === 'Process') {
    //           item.data.dipay_status_color = 'tag-warning';
    //         } else if (item.data.transaction_status_desc === 'Failed') {
    //           item.data.dipay_status_color = 'tag-danger';
    //         }
    //         return item;
    //       });
    //   } catch (error) {
    //     console.error(error);
    //   } finally {
    //     this.loadingDipayStatus = false
    //   }
    // },
    async checkDipayStatus(selected_transaction) {
      this.loadingDipayStatus = true
      try {
        if (!selected_transaction.owned_by) {
          return;
        }
        this.data_dipay = [];
        const requestBody = {
          invoice_number: selected_transaction.invoice_number, 
          owned_by: selected_transaction.owned_by
        };
        const response = await this.$store.dispatch(STATUS_DIPAY, requestBody);
        // console.log("Response from STATUS_DIPAY:", response);
        if (response.data.transaction_status_desc === 'Success') {
          response.data.dipay_status_color = 'tag-success';
        } else if (response.data.transaction_status_desc === 'Refund Success' || response.data.transaction_status_desc === 'Refunded') {
          response.data.dipay_status_color = 'tag-teal';
        } else if (response.data.transaction_status_desc === 'Waiting payment' || response.data.transaction_status_desc === 'Process') {
          response.data.dipay_status_color = 'tag-warning';
        } else if (response.data.transaction_status_desc === 'Failed') {
          response.data.dipay_status_color = 'tag-danger';
        }
        this.data_dipay = response.data;
        // console.log('NIH DATA DIPAY', this.data_dipay)
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingDipayStatus = false
      }
    },
    async checkDipayLog(selected_transaction) {
      this.loadingDipayLog = true
      try {
        if (!selected_transaction.owned_by) {
          return;
        }
        const requestBody = {
          invoice_number: selected_transaction.invoice_number, 
          owned_by: selected_transaction.owned_by
        };
        const response = await this.$store.dispatch(LOG_DIPAY, requestBody);
        this.log_dipay = response
        console.log('Response:', response);
        console.log('log dipay:', this.log_dipay);
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingDipayLog = false
      }
    },
    async checkProductStatus(id) {
      this.loadingProductStatus = true
      this.product_status = [];
      console.log('transactionDetails checkProductStatus ', this.transactionDetails);
      console.log('ID ', id);
      // console.log('product vendor', this.transactionDetails.product_vendor)
      try {
        //  transactionDetails ga kosong
        if (!this.transactionDetails || this.transactionDetails.length === 0) {
          console.error("Transaction details are  empty");
          return;
        }
        //  detail transaksi pertama dari array
        const firstTransactionDetails = this.transactionDetails[0];
        // get order_id, source_type, dan product_vendor from firstTransactionDetails
        const { order_id, source_type, product_vendor } = firstTransactionDetails;
        //  order_id, source_type, dan product_vendor ad?
        if (!order_id || !source_type || !product_vendor) {
          console.error("Incomplete data for request");
          return;
        }
        // req body
        const requestBody = {
          data: {
            order_id,
            source_type,
          },
          product_vendor,
        };
        const response = await this.$store.dispatch(STATUS_PRODUCT, requestBody);
        this.product_status = response.data.rows;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingProductStatus = false; 
      }
    },
    // the same month
     isSameMonth(created_at_str) {
        const createdDate = new Date(created_at_str);
        const createdMonth = createdDate.getMonth();
        const currentMonth = new Date().getMonth();
        return createdMonth === currentMonth;
    },
    // format
    formatCurrency(value) {
      if (value) {
        const hasDecimalPart = value % 1 !== 0;
        const formattedIntegerPart = Math.floor(value).toLocaleString('id-ID');
        const formattedValue = `Rp. ${formattedIntegerPart}`;
        return hasDecimalPart ? `Rp. ${value.toLocaleString('id-ID')}` : formattedValue;
      } else {
        return value;
      }
    },
    // in a month FIXXXX
    isRefundDisabled(selected_transaction) {
      if (!selected_transaction || !selected_transaction.created_at_str || selected_transaction.status === 7 ) {
        return true;
      }
      const currentTime = new Date();
      const createdAtTime = new Date(selected_transaction.created_at_str);
      // hitung the diff in months n years
      const currentMonth = currentTime.getMonth();
      const currentYear = currentTime.getFullYear();
      const createdMonth = createdAtTime.getMonth();
      const createdYear = createdAtTime.getFullYear();
      // hitug the difference in milliseconds
      const timeDifferenceMilliseconds = currentTime - createdAtTime;
      // cek if the created month n year are the same as the current month n year
      const isInSameMonth = currentMonth === createdMonth && currentYear === createdYear;
      return (
        selected_transaction.status_str === "Refund Success" ||
        (
          selected_transaction.status_str !== 'Success' &&
          selected_transaction.status_str !== 'Refund Requested'
        ) ||
        (
          selected_transaction.details &&
          (
            selected_transaction.details.some(detail => detail.order_detail_status !== 0 && detail.order_detail_status !== 1 && detail.order_detail_status !== null || detail.vendor.vendor_name !== 'Mobile Pulsa' )
          )
        ) ||
        !isInSameMonth ||
        timeDifferenceMilliseconds < (30 * 60 * 1000) 
      );
    },
    // partial
    // isRefundDisabled(selected_transaction) {
    //   if (!selected_transaction || !selected_transaction.details || selected_transaction.details.length === 0) {
    //     return true; // No details available, disable the refund button
    //   }

    //   const currentTime = new Date();

    //   // Iterate over each product detail
    //   for (const detail of selected_transaction.details) {
    //     const createdAtTime = new Date(detail.created_at_str);

    //     const currentMonth = currentTime.getMonth();
    //     const currentYear = currentTime.getFullYear();
    //     const createdMonth = createdAtTime.getMonth();
    //     const createdYear = createdAtTime.getFullYear();

    //     const timeDifferenceMilliseconds = currentTime - createdAtTime;
    //     const isInSameMonth = currentMonth === createdMonth && currentYear === createdYear;

    //     // Check conditions for each detail
    //     if (
    //       detail.status_str !== "Refund Success" &&
    //       (
    //         detail.status_str === 'Success' ||
    //         detail.status_str === 'Refund Requested' ||
    //         (
    //           detail.order_detail_status === 0 || 
    //           detail.order_detail_status === 1 || 
    //           detail.order_detail_status === null ||
    //           detail.vendor.vendor_name === 'Mobile Pulsa'
    //         ) ||
    //         isInSameMonth ||
    //         timeDifferenceMilliseconds >= (30 * 60 * 1000) 
    //       )
    //     ) {
    //       return false; // Enable the refund button if any condition is met for any detail
    //     }
    //   }

    //   // If none of the details meet the conditions, disable the refund button
    //   return true;
    // },
    refund() {
      this.dialogVisibleOtp = true;
    },
    async confirm() {
      try {
        // const response = await this.$store.dispatch(GET_REFUND_OTP, { company_id: this.selectedOption });
        const response = await this.$store.dispatch(GET_REFUND_OTP, { company_id: this.company_id });
        console.log(response); 
        this.data_OTP = response.data;
        this.otpModalVisible = true;
        this.dialogVisibleOtp = false;
      } catch (error) {
        this.$message.error('Failed to get OTP');
        console.error(error);
      }
    },
    closeOTPModal() {
      this.$refs.otpForm.resetFields();
      this.otpModalVisible = false;
    },
    async verifyOTP(selected_transaction) {
      try {
        if (!selected_transaction.owned_by) {
          return;
        }
        const requestBody = {
          invoice_number: selected_transaction.invoice_number, 
          owned_by: selected_transaction.owned_by,
          reason: this.otpForm.reason,
          otp: this.otpForm.otp,
          token: this.data_OTP.token,
          user_id: this.user_id
        };
        const response = await this.$store.dispatch(REFUND_VERIFY, { data: requestBody });
        this.otpForm.otp = '';
        this.otpForm.reason = '';
        this.closeOTPModal();
        this.$message.success('Product successfully refunded');
        this.data_Verify = response;
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          const errorMessage = error.response.data.error;
          if (errorMessage.code === 1019 && errorMessage.title === 'invalid_otp') {
            this.$message.error('OTP is not valid');
          } else if (errorMessage.code === 400 && errorMessage.title.includes('Transaction Not Found')) {
            this.$message.error('The transaction has been successfully refunded');
          } else {
            this.$message.error('An error occurred while processing your request.');
          }
        } else {
          this.$message.error('An error occurred while processing your request.');
        }
        this.closeOTPModal();
        console.error(error);
      }
    },
    formatOTP() {
      this.otpForm.otp = this.otpForm.otp.replace(/\D/g, '');
    },
    // New Repurchase
    repurchase() {
      this.dialogVisibleOtpRepurchase = true;
    },
    async confirmRepurchase() {
      try {
        const response = await this.$store.dispatch(GET_REPURCHASE_OTP, { company_id: this.selectedOption });
        console.log(response); 
        this.data_OTP_repurchase = response.data;
        this.otpModalVisibleRepurchase = true;
        this.dialogVisibleOtpRepurchase = false;
      } catch (error) {
        this.$message.error('Failed to get OTP');
        console.error(error);
      }
    },
    async verifyOTPRepurchase(selected_transaction) {
      try {
        if (!selected_transaction.owned_by) {
          return;
        }
        const requestBody = {
          invoice_number: selected_transaction.invoice_number, 
          owned_by: selected_transaction.owned_by,
          reason: this.otpForm.reason,
          otp: this.otpForm.otp,
          token: this.data_OTP.token,
          user_id: this.user_id
        };
        const response = await this.$store.dispatch(REPURCHASE_VERIFY, { data: requestBody });
        this.otpForm.otp = '';
        this.otpForm.reason = '';
        this.closeOTPModal();
        this.$message.success('Product successfully repurchased');
        this.data_verify_repurchase = response;
      } catch (error) {
        this.$message.error('Transaction Not Found');
        this.closeOTPModal();
        console.error(error);
      }
    },
    toggleCollapse() {
      if (!this.collapseOpened) {
        if (this.selected_transaction) {
        this.checkDipayStatusAndLog(this.selected_transaction);
      }
        this.collapseOpened = true;
      } else {
        this.collapseOpened = false;
      }
    },
    toggleCollapseProduct(id) {
      if (!this.collapseOpenedProduct) {
        this.checkProductStatus(id);
        this.collapseOpenedProduct = true;
      } else {
        this.collapseOpenedProduct = false;
      }
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}

.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}

.child2-sticky {
  position: sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}
/* del */
.table-container {
  width: 100%;
  overflow-x: auto;
}

.table-responsive {
  overflow-x: auto;
}

.buttonload {
  background-color: #006EA5; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px /* Set a font size */
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the transparency as needed */
  z-index: 999; /* Ensure the overlay is above other elements */
}

.custom-table-collapse {
  background-color: rgba(198, 135, 68, 0.853); /* Example background color */
  /* Add more custom styling as needed */
}

.b-collapse-transition {
  transition: height 0.3s ease-in-out;
}

.tooltip-container {
  margin-top: 10px;
}

.tooltip-inner {
  color: #FA7F03;
  text-align: center;
  background-color: #000000;
  border-radius: 4px;
  font-size: 11px
}
</style>